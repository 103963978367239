import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import {
    handleAnalyticsData, handleAnalyticsProdFilters, handleAnalyticsTabIndex,
    handleForecast,
    handleFullScrnAnalytics, handleNormalize,
    handleShowAndHideTable, toggleViewAnalytics
} from "../store/actions/wells-rigs-action";
import LineChart from "../common/LineChart";
import { ActionType } from "../models/redux-models";
import {
    ANALYTICS_CUM_TAB, GAS, LINE_CHART_MAX_ITEM_ALLOWED, ANALYTICS_MONTHLY_TAB, OIL,
    LINE_CHART_XAXIS_FILTERS, VERTICAL, DropDownOption, capitalize, ANALYTICS_DEFAULT_WIDTH,
    getHeaderOfCSV,
} from "../../utils/helper";
import moment from "moment";
import { toast } from "react-toastify";
import exportFromJSON from "export-from-json";
import { handleFreeTrialDownAlertMsgModal } from "../store/actions/modal-actions";

const AnalyticsRightSidebarProductionTab = () => {
    const {
        wellsAndRigs: {
            analyticsData: { oil_data, gas_data, monthlyDataLoading, cumDataLoading, type, cum_gas_data, cum_oil_data, xAxisFilter, xAxisFilterCum, normalized, action, action_cum, apiList, apiListObj, apiListObjLength,
            },
            checkedItemList,
            showTable,
            analyticsTabIndex,
        },
        auth: { user: { access_token, company_configs: { download_enabled, free_trial_period_enabled } } },
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();
    const typeCurve = (xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"] || xAxisFilterCum === LINE_CHART_XAXIS_FILTERS["Producing Time"]) ? true : false;

    const [state, setState] = useState<{
        //  dowData: DowData[], 
        showDropDownOption: boolean, closeOilMaxMsg: boolean, closeGasMaxMsg: boolean
    }>({
        //  dowData: [],
        showDropDownOption: false, closeOilMaxMsg: false, closeGasMaxMsg: false
    })

    const {
        // dowData,
        showDropDownOption, closeOilMaxMsg, closeGasMaxMsg } = state;


    const RenderForecastBtn = ({ typePro }: { typePro: typeof OIL | typeof GAS }) => {
        return <div className="graphBtn">
            <button
                onClick={() => {
                    if (checkedItemList.length > 1) { toast.info("Please limit selection to one well for forecasting."); }
                    else {
                        dispatch(handleShowAndHideTable(!showTable))
                        dispatch(toggleViewAnalytics());
                        dispatch(handleFullScrnAnalytics({ fullScrnAnalytics: true, fullScrnAnalyticsType: typePro === OIL ? OIL : GAS }))
                        dispatch(handleForecast(true))
                    }
                }}
                className={`btn ${((type === ANALYTICS_MONTHLY_TAB && xAxisFilter === LINE_CHART_XAXIS_FILTERS["Date Time"]) || (type === ANALYTICS_CUM_TAB && xAxisFilterCum === LINE_CHART_XAXIS_FILTERS["Date Time"])) ? "active" : ""}`}
                disabled={((type === ANALYTICS_MONTHLY_TAB && xAxisFilter === LINE_CHART_XAXIS_FILTERS["Date Time"]) || (type === ANALYTICS_CUM_TAB && xAxisFilterCum === LINE_CHART_XAXIS_FILTERS["Date Time"])) ? false : true}>Run Forecast{((type === ANALYTICS_MONTHLY_TAB && xAxisFilter === LINE_CHART_XAXIS_FILTERS["Date Time"]) || (type === ANALYTICS_CUM_TAB && xAxisFilterCum === LINE_CHART_XAXIS_FILTERS["Date Time"])) ? <></> : <span>Switch to Date Time view to enable Run Forecast functionality.</span>}</button>
            <button onClick={() => {
                if (checkedItemList.length > 1 && ((type === ANALYTICS_CUM_TAB && action_cum === "none") || (type === ANALYTICS_MONTHLY_TAB && action === "none"))) { toast.info(typeCurve ? "Please limit selection to one well to run type curve or perform aggregation." : "Please limit selection to one well for forecasting."); } else {
                    dispatch(handleShowAndHideTable(!showTable))
                    dispatch(toggleViewAnalytics());
                    dispatch(handleFullScrnAnalytics({ fullScrnAnalytics: true, fullScrnAnalyticsType: typePro === OIL ? OIL : GAS }))
                    dispatch(handleForecast(true))
                }
            }}
                className={`btn ${((type === ANALYTICS_MONTHLY_TAB && xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"]) || (type === ANALYTICS_CUM_TAB && xAxisFilterCum === LINE_CHART_XAXIS_FILTERS["Producing Time"])) ? "active" : ""}`}
                disabled={((type === ANALYTICS_MONTHLY_TAB && xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"]) || (type === ANALYTICS_CUM_TAB && xAxisFilterCum === LINE_CHART_XAXIS_FILTERS["Producing Time"])) ? false : true}>Run Type Curve{((type === ANALYTICS_MONTHLY_TAB && xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"]) || (type === ANALYTICS_CUM_TAB && xAxisFilterCum === LINE_CHART_XAXIS_FILTERS["Producing Time"])) ? <></> : <span>Switch to Producing Time view to enable Type Curve functionality.</span>}</button>
        </div>
    }

    return (
        <>
            <div className="analyticsTab">
                <ul className="nav" id="myTabaoi" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link active"
                            id="productionAnalytics-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#productionAnalytics"
                            type="button"
                            role="tab"
                            onClick={() => {
                                if (analyticsTabIndex !== 0) {
                                    dispatch(handleFullScrnAnalytics({ fullScrnAnalytics: false }))
                                    dispatch(handleAnalyticsTabIndex(0))
                                }
                            }}
                            aria-controls="productionAnalytics"
                            aria-selected={true}
                        >
                            <span className="text">Production</span>{" "}
                        </button>
                    </li>
                </ul>
            </div>
            <div className="tab-content tabSection" id="myTabContentaoi">
                <div
                    className="tab-pane fade show active"
                    id="productionAnalytics"
                    role="tabpanel"
                    aria-labelledby="productionAnalytics-tab"
                >
                    <Scrollbars
                        className="cardsection-scroll"
                        renderThumbVertical={(props) => (
                            <div {...props} className="thumb-vertical" />
                        )}
                        renderTrackVertical={(props) => (
                            <div {...props} className="track-vertical" />
                        )}
                    >
                        <div className="analyticsHeader">
                            <div className="navli monthly">
                                <Link className={type === ANALYTICS_MONTHLY_TAB ? "active" : ""} to={""}
                                    onClick={() => {
                                        if (type !== ANALYTICS_MONTHLY_TAB) {
                                            dispatch(handleFullScrnAnalytics({ fullScrnAnalytics: false }))
                                            dispatch(handleAnalyticsProdFilters({
                                                type: ANALYTICS_MONTHLY_TAB
                                            }));
                                        }
                                    }}
                                >Monthly</Link>
                                <Link className={type === ANALYTICS_CUM_TAB ? "active" : ""} to={""} onClick={() => {
                                    if (type !== ANALYTICS_CUM_TAB) {
                                        dispatch(handleFullScrnAnalytics({ fullScrnAnalytics: false }))
                                        dispatch(handleAnalyticsProdFilters({ ...(checkedItemList.length > LINE_CHART_MAX_ITEM_ALLOWED && action_cum === "none" && { action: 'average' }), type: ANALYTICS_CUM_TAB }));
                                    }
                                }}>Cum</Link>
                            </div>
                            <div className="navli timely">
                                {Object.keys(LINE_CHART_XAXIS_FILTERS).map((item, index) => {
                                    let tempValue = LINE_CHART_XAXIS_FILTERS[item as keyof typeof LINE_CHART_XAXIS_FILTERS];

                                    return <Link className={tempValue === (type === ANALYTICS_MONTHLY_TAB ? xAxisFilter : xAxisFilterCum) ? "active" : ""} to={""} key={index}
                                        onClick={() => {
                                            if ((type === ANALYTICS_MONTHLY_TAB ? xAxisFilter : xAxisFilterCum) !== tempValue) {
                                                dispatch(handleAnalyticsProdFilters({ xAxisFilterVal: tempValue, type }));
                                                if ((type === ANALYTICS_MONTHLY_TAB ? action : action_cum) !== "none") {
                                                    type === ANALYTICS_MONTHLY_TAB && dispatch(handleAnalyticsData({ oilList: [], gasList: [], type: ANALYTICS_MONTHLY_TAB, monthlyDataLoading: true }));

                                                    type === ANALYTICS_CUM_TAB && dispatch(handleAnalyticsData({ oilList: [], gasList: [], type: ANALYTICS_CUM_TAB, cumDataLoading: true }));
                                                }
                                            }
                                        }}
                                    >{item}</Link>
                                })}
                            </div>
                        </div>
                        <div className="card-box">
                            <div className="card-box-header">
                                <div className="labelheading">Liquids Production</div>
                                <div className="action-btn">
                                    <Link to="" onClick={() => {
                                        dispatch(handleShowAndHideTable(!showTable))
                                        dispatch(toggleViewAnalytics());
                                        dispatch(handleFullScrnAnalytics({ fullScrnAnalytics: true, fullScrnAnalyticsType: OIL }))
                                    }}>
                                        <img
                                            src="images/full-screen.svg"
                                            alt=""
                                        />
                                    </Link>
                                    <Link to="" onClick={() => {
                                        if (free_trial_period_enabled && !download_enabled) {
                                            dispatch(handleFreeTrialDownAlertMsgModal(true))
                                            return
                                        }
                                        let tempData: { well_api: string, production_date: string, production_quantity: string, production_month?: number }[] = [];
                                        (type === ANALYTICS_MONTHLY_TAB ? oil_data : cum_oil_data).forEach((item) => {
                                            let nonZero = false;
                                            let index = 0;
                                            item.values.forEach((_item) => {
                                                if (
                                                    (_item.production_quantity === 0 ||
                                                        _item.production_quantity === null) &&
                                                    !nonZero
                                                ) {
                                                } else {
                                                    !nonZero && (nonZero = true);
                                                    tempData.push({
                                                        well_api: item.name,
                                                        production_date: `${_item.production_date}`,
                                                        production_quantity: _item.production_quantity.toString(),
                                                        // production_month: tempData.length
                                                        production_month: index
                                                    })
                                                    index = index + 1
                                                }

                                            });
                                        });
                                        exportFromJSON({
                                            data: tempData, fileName: `ED_Data_Export_${moment(new Date()).format(
                                                "MMM-DD-YYYY, h:mm:ss a"
                                            )}`,
                                            exportType: "csv",
                                            fields: [
                                                (typeCurve ? "production_month" : "production_date"),
                                                "well_api",
                                                "production_quantity",
                                            ],
                                            beforeTableEncode: entries => entries.map(
                                                ({ fieldName, fieldValues }) => {
                                                    return { fieldName: getHeaderOfCSV(fieldName, false, normalized, type, action, action_cum), fieldValues };
                                                }
                                            )
                                        })
                                    }}>
                                        <img src="images/download.svg" alt="" />
                                    </Link>
                                    <Link to="">
                                        <img src="images/three-dots.svg" alt="" />
                                    </Link>
                                </div>
                            </div>
                            {checkedItemList.length > LINE_CHART_MAX_ITEM_ALLOWED && !sessionStorage.getItem("oilMaxMsg") && !closeOilMaxMsg ? <div className="excedsMessage">
                                <div className="text-block">
                                    <img src="images/exclamation.svg" alt="" />
                                    Your selection exceeded 50 wells, so we've aggregated them for simplicity. You can change the aggregation method from the dropdown.
                                </div>
                                <span className="close" onClick={() => {
                                    setState((prev) => ({ ...prev, closeOilMaxMsg: true }))
                                }}><img src="images/close.svg" alt="" /></span>

                                <div className="custom-checkbox">
                                    <input name="rememberMe" className="form-control checkmark" type="checkbox" id="exceds" onChange={e => {
                                        const { checked } = e.target;
                                        if (checked) {
                                            sessionStorage.setItem("oilMaxMsg", "true");
                                        } else {
                                            sessionStorage.removeItem("oilMaxMsg");
                                        }
                                    }} />
                                    <label htmlFor="exceds" className="custom-label"> Don’t show this message again</label>
                                </div>
                            </div> : <></>}
                            <div className="normalize">
                                <div className={"aggregation"}>
                                    Aggregation: <span className="cursor" onClick={() => setState((prev) => ({ ...prev, showDropDownOption: true }))}>{type === ANALYTICS_MONTHLY_TAB ? capitalize(action) : capitalize(action_cum)}</span>
                                    <div className={showDropDownOption ? "dropmenu" : "d-none"}>
                                        <ul>
                                            {DropDownOption.map((item, index) => {
                                                return <li key={index}
                                                    className={`${item.value === "none" && checkedItemList.length > LINE_CHART_MAX_ITEM_ALLOWED ? "disabled" : "cursor"} ${type === ANALYTICS_MONTHLY_TAB && action === item.value ? "selected" : type === ANALYTICS_CUM_TAB && action_cum === item.value ? "selected" : ''}`}
                                                    onClick={(() => {
                                                        if (item.value === "none" && checkedItemList.length > LINE_CHART_MAX_ITEM_ALLOWED) {
                                                            return
                                                        }


                                                        dispatch(handleAnalyticsProdFilters({ action: item.value as ActionType, type }));

                                                        type === ANALYTICS_MONTHLY_TAB && dispatch(handleAnalyticsData({ oilList: [], gasList: [], type: ANALYTICS_MONTHLY_TAB, monthlyDataLoading: true }));

                                                        type === ANALYTICS_CUM_TAB && dispatch(handleAnalyticsData({ oilList: [], gasList: [], type: ANALYTICS_CUM_TAB, cumDataLoading: true }));

                                                        setState((prev) => ({ ...prev, showDropDownOption: false }));

                                                    })}>{item.label}</li>
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className={`${apiListObjLength === apiListObj.length ? "grey" : ""} noramlizeBlock`}>
                                    Normalize&nbsp;<img src="images/exclamation.svg" alt="" />
                                    <label className={`switch`}>
                                        <input
                                            disabled={apiListObjLength === apiListObj.length ? true : false}
                                            type="checkbox"
                                            checked={normalized ? true : false}
                                            onChange={(e) => {
                                                const { checked } = e.target
                                                dispatch(handleNormalize(checked))
                                            }
                                            }
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            <h3 style={{ display: "none" }}>Cumulative</h3>
                            <div className="cardInfo" style={{ display: "none" }}>
                                <ul>
                                    <li className="active">
                                        <span className="highlight oil"></span>{" "}
                                        Oil
                                        <div className="result">
                                            40.21 <sub>MMbbl</sub>
                                        </div>
                                    </li>
                                    <li>
                                        <span className="highlight gas"></span>{" "}
                                        Gas
                                        <div className="result">
                                            53.06 <sub>Bcf</sub>
                                        </div>
                                    </li>
                                    <li>
                                        <span className="highlight boe"></span>{" "}
                                        BOE
                                        <div className="result">
                                            49.05 <sub>MMBOE</sub>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="garph">
                                {/* <img src="images/graph-1.svg" alt="" /> */}
                                {type === ANALYTICS_MONTHLY_TAB ? apiList.length === 0 ? <></> : (monthlyDataLoading) ? <div className="norecord">Loading...</div> : <LineChart usedFor={OIL} yLabel={`${normalized ? "bbl/ft" : "bbl"}`} id={OIL}
                                    useDateXAxis={xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"] ? false : true} /> : <></>}
                                {type === ANALYTICS_CUM_TAB ? apiList.length === 0 ? <></> : (cumDataLoading) ? <div className="norecord">Loading...</div> : <LineChart usedFor={OIL} yLabel={`${normalized ? "bbl/ft" : "bbl"}`} id={OIL} useDateXAxis={xAxisFilterCum === LINE_CHART_XAXIS_FILTERS["Producing Time"] ? false : true} /> : <></>}
                            </div>
                            <RenderForecastBtn typePro={OIL} />
                        </div>
                        <div className="card-box">
                            <div className="card-box-header">
                                <div className="labelheading">Gas Production</div>
                                <div className="action-btn">
                                    <Link to="" onClick={() => {
                                        dispatch(handleShowAndHideTable(!showTable))
                                        dispatch(toggleViewAnalytics());
                                        dispatch(handleFullScrnAnalytics({ fullScrnAnalytics: true, fullScrnAnalyticsType: GAS }))
                                    }}>
                                        <img
                                            src="images/full-screen.svg"
                                            alt=""
                                        />
                                    </Link>
                                    <Link to="" onClick={() => {
                                        if (free_trial_period_enabled && !download_enabled) {
                                            dispatch(handleFreeTrialDownAlertMsgModal(true))
                                            return
                                        }
                                        let tempData: { well_api: string, production_date: string, production_quantity: string, production_month?: number }[] = [];
                                        (type === ANALYTICS_MONTHLY_TAB ? gas_data : cum_gas_data).forEach((item) => {
                                            let nonZero = false;
                                            let index = 0;
                                            item.values.forEach((_item) => {
                                                if (
                                                    (_item.production_quantity === 0 ||
                                                        _item.production_quantity === null) &&
                                                    !nonZero
                                                ) {
                                                } else {
                                                    !nonZero && (nonZero = true);
                                                    tempData.push({
                                                        well_api: item.name,
                                                        production_date: `${_item.production_date}`,
                                                        production_quantity: _item.production_quantity.toString(),
                                                        // production_month: tempData.length
                                                        production_month: index
                                                    })
                                                    index = index + 1;
                                                }

                                            });
                                        });
                                        exportFromJSON({
                                            data: tempData, fileName: `ED_Data_Export_${moment(new Date()).format(
                                                "MMM-DD-YYYY, h:mm:ss a"
                                            )}`,
                                            exportType: "csv",
                                            fields: [
                                                (typeCurve ? "production_month" : "production_date"),
                                                "well_api",
                                                "production_quantity",
                                            ],
                                            beforeTableEncode: entries => entries.map(
                                                ({ fieldName, fieldValues }) => {
                                                    return { fieldName: getHeaderOfCSV(fieldName, true, normalized, type, action, action_cum), fieldValues };
                                                }
                                            )
                                        })
                                    }}>
                                        <img src="images/download.svg" alt="" />
                                    </Link>
                                    <Link to="">
                                        <img src="images/three-dots.svg" alt="" />
                                    </Link>
                                </div>
                            </div>
                            <h3 style={{ display: "none" }}>Cumulative</h3>
                            <div className="cardInfo" style={{ display: "none" }}>
                                <ul>
                                    <li className="active">
                                        <span className="highlight oil"></span>{" "}
                                        Oil
                                        <div className="result">
                                            40.21 <sub>MMbbl</sub>
                                        </div>
                                    </li>
                                    <li>
                                        <span className="highlight gas"></span>{" "}
                                        Gas
                                        <div className="result">
                                            53.06 <sub>Bcf</sub>
                                        </div>
                                    </li>
                                    <li>
                                        <span className="highlight boe"></span>{" "}
                                        BOE
                                        <div className="result">
                                            49.05 <sub>MMBOE</sub>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {checkedItemList.length > LINE_CHART_MAX_ITEM_ALLOWED && !sessionStorage.getItem("gasMaxMsg") && !closeGasMaxMsg ? <div className="excedsMessage">
                                <div className="text-block">
                                    <img src="images/exclamation.svg" alt="" />
                                    Your selection exceeded 50 wells, so we've aggregated them for simplicity. You can change the aggregation method from the dropdown.
                                </div>
                                <span className="close" onClick={() => {
                                    setState((prev) => ({ ...prev, closeGasMaxMsg: true }))
                                }}><img src="images/close.svg" alt="" /></span>

                                <div className="custom-checkbox">
                                    <input name="rememberMeGas" className="form-control checkmark" type="checkbox" id="gasMaxMsg" onChange={e => {
                                        const { checked } = e.target;
                                        if (checked) {
                                            sessionStorage.setItem("gasMaxMsg", "true");
                                        } else {
                                            sessionStorage.removeItem("gasMaxMsg");
                                        }
                                    }} />
                                    <label htmlFor="gasMaxMsg" className="custom-label"> Don’t show this message again</label>
                                </div>
                            </div> : <></>}
                            <div className="garph">
                                {type === ANALYTICS_MONTHLY_TAB ? apiList.length === 0 ? <></> : (monthlyDataLoading) ? <div className="norecord">Loading...</div> : <LineChart usedFor={GAS} yLabel={`${normalized ? "Mcf/ft" : "Mcf"}`} id={GAS} useDateXAxis={xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"] ? false : true} /> : <></>}
                                {type === ANALYTICS_CUM_TAB ? apiList.length === 0 ? <></> : (cumDataLoading) ? <div className="norecord">Loading...</div> : <LineChart usedFor={GAS} yLabel={`${normalized ? "Mcf/ft" : "Mcf"}`} id={GAS} useDateXAxis={xAxisFilterCum === LINE_CHART_XAXIS_FILTERS["Producing Time"] ? false : true} /> : <></>}
                            </div>
                            <RenderForecastBtn typePro={GAS} />
                        </div>
                    </Scrollbars>
                </div>
                <div
                    className="tab-pane fade"
                    id="welltype"
                    role="tabpanel"
                    aria-labelledby="welltype-tab"
                >
                    <div className="tabBlockContent">Well</div>
                </div>
            </div>
        </>
    );
};

export default AnalyticsRightSidebarProductionTab;
