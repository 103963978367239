import React from 'react'
import GlobalModal from '../GlobalModal'
import { Button } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'
import { handleMonthlyToYearlyUpgradeMsgModal } from '../../store/actions/modal-actions';
import { handleYearlyAndMonthlyTab } from '../../store/actions/cart-select-basin-county-actions';

function MonthlyToYearlyUpgradeMsgModal() {
    const { modal: { monthlyToYearlyUpgradeMsgModal } } = useAppSelector(state => state);
    const dispatch = useAppDispatch();

    const handleClose = () => {
        dispatch(handleMonthlyToYearlyUpgradeMsgModal(false));

    }
    const continueBtnClick = () => {
        dispatch(handleYearlyAndMonthlyTab(true))
        dispatch(handleMonthlyToYearlyUpgradeMsgModal(false));

    }

    if (!monthlyToYearlyUpgradeMsgModal) {
        return <></>
    }



    return (
        <GlobalModal
            show={monthlyToYearlyUpgradeMsgModal}
            enableFooter={true}
            center={true}
            onHide={handleClose}
            contentClass={"subscribeModal"}
            footerClass={"action-footer justify"}
            footRender={
                <>
                    <Button variant="btn btn-outline-white" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="btn btn-green" onClick={continueBtnClick}>
                        Continue
                    </Button>
                </>
            }
        >
            <div className="seggestionModalContent upgradeYearlyPlan">
                <h3 className='text-center'>Upgrade to Yearly Plan</h3>
                <p className='free-trialtext'>Upgrading will convert all your current and future subscriptions to yearly billing.</p>


                <div className="ready">Do you wish to continue?</div>

            </div>
        </GlobalModal>
    )
}

export default MonthlyToYearlyUpgradeMsgModal