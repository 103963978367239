import React from 'react'
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { MONTHS, calculateDiscount, cartSelectBasin, formatUSDCurrency } from '../../utils/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import { getBasinCountyDetails } from '../store/actions/cart-select-basin-county-actions';

function GoNational({ hideBtn }: { hideBtn?: boolean }) {
    const {
        cartSelectBasinCounty: { yearly_discount_percent, yearly },
        subscriptionSettings: { go_nation, subscriptionData, upgrade_subscription },
        auth: { user: { access_token, company_configs: { trial_expired, free_trial_period_enabled } } }
    } = useAppSelector((state) => state);
    const location = useLocation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    return (
        <div
            className={`${(subscriptionData && (subscriptionData['details'] || []).find((item) => item.line_item_type === 3)) || !go_nation || (free_trial_period_enabled && !trial_expired && !upgrade_subscription) ? "d-none" : ''} gonatinalAccess`}
        >
            <div className="doller">
                <div className="graph">
                    <img src={`${process.env.REACT_APP_ED_DATA_CDN_API}/company_logo/${go_nation?.file_name}`} alt="" />
                </div>
                <span className={location.pathname === cartSelectBasin ? '' : "d-none"}>{formatUSDCurrency(((go_nation?.price as number) - Number(calculateDiscount((go_nation?.price as number), yearly_discount_percent))) * (yearly ? MONTHS : 1))}</span>
                <span className={location.pathname === cartSelectBasin ? 'fullitemprice' : "d-none"}>{formatUSDCurrency(((go_nation?.price) as number) * (yearly ? MONTHS : 1))}</span>
            </div>
            <div className="dollerInfo">
                <h3>{`Go ${go_nation?.plan_name}`}!
                    {/* <span>{`${go_nation?.short_description}`}</span> */}
                </h3>
                {/* <p>{go_nation?.description}</p> */}
                {hideBtn ? <></> : <button
                    onClick={() => {
                        if (location.pathname !== cartSelectBasin) {
                            navigate(cartSelectBasin, { state: { add_go_national: true } })
                            return
                        }

                        dispatch(
                            getBasinCountyDetails(access_token, {
                                search: "Go National",
                                category: "nation",
                            }))
                    }}

                    className={`btn btn-green ${location.pathname === cartSelectBasin ? "width100" : ''}`}>{`${location.pathname === cartSelectBasin ? "Get Nationwide Access" : "Upgrade Now"}`}</button>}
            </div>
        </div>
    )
}

export default GoNational