import React from "react";
import { cardBottomProps } from "../models/page-props";
import { MONTHS, USDollar, calculateDiscount, formatUSDCurrency } from "../../utils/helper";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { hideCheckOutModal, showCheckOutModal } from "../store/actions/modal-actions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { clearSubscriptionData, freeTrialSubscription } from "../store/actions/subscription-settings-actions";
import { clearCartItemsList } from "../store/actions/cart-select-basin-county-actions";

function CardBottom(props: cardBottomProps) {
    const { cancelClick, total } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        auth: {
            user: {
                company_data: { company_id },
                company_configs: { free_trial_period_enabled, trial_expired }
            },
        },
        cartSelectBasinCounty: {
            // cartItemsTotal,
            cartModified,
            yearly,
            yearly_discount_percent
        },
        subscriptionSettings: { upgrade_subscription }
    } = useAppSelector((state) => state);
    return (
        <div className="bottom-part">
            {
                free_trial_period_enabled && !upgrade_subscription ? <>
                    <div className={`promocode ${free_trial_period_enabled && !trial_expired ? "d-none" : ""}`}><Link to="">Add promo code</Link></div>
                    <div className="totalb">
                        <p className="totalaftertrial">Total after trial<span>{USDollar.format(total)}</span></p>
                        <p className="totaldue">Total due today<span>$0.00</span></p>
                    </div>
                    <button type="button" onClick={() => {
                        dispatch(freeTrialSubscription({ amount: total })).then(res => {
                            const { status, msg } = res
                            if (status === 200) {
                                dispatch(hideCheckOutModal());
                                dispatch(clearCartItemsList());
                                dispatch(clearSubscriptionData())
                                toast.success(msg);
                                navigate("/search")
                            } else {
                                toast.error(msg)
                            }
                        })
                    }} className="btn btn-green width100">Start Your Free Trial</button>
                </> : <>
                    {yearly ? <div className="sub-total">
                        Sub total: <span>{USDollar.format(total * MONTHS)}</span>
                    </div> : <></>}
                    {yearly ? <div className="discount">
                        Discount ({`${yearly_discount_percent}`}%): <span>{USDollar.format(((total * MONTHS * yearly_discount_percent) / 100))}</span>
                    </div> : <></>}
                    <div className="total">
                        Billed now: <span>{yearly ?
                            // formatUSDCurrency(Number(USDollar.format(total * MONTHS).replace("$", ""))
                            //     - Number(USDollar.format(((total * MONTHS * yearly_discount_percent) / 100)).replace("$", "")))
                            USDollar.format(Number((total * MONTHS).toFixed(2)) - Number(((total * MONTHS * yearly_discount_percent) / 100).toFixed(2)))
                            : USDollar.format(total)}</span>
                    </div>
                    <div className="button">
                        <button
                            type="button"
                            className="btn btn-cancel"
                            onClick={cancelClick}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-green"
                            disabled={!cartModified ? true : false}
                            onClick={() => {
                                if (company_id) {
                                    dispatch(showCheckOutModal());
                                } else {
                                    toast.info("Please add your company details.");
                                    navigate("/company-settings", {
                                        state: { checkout: true },
                                    });
                                }
                            }}
                        >
                            Checkout
                        </button>
                    </div>
                </>
            }
        </div>
    );
}

export default CardBottom;
