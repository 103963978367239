import React, { useEffect, useMemo, useRef, useState } from "react";
import TabHeadingSec from "./TabHeadingSec";
// import Filter from "./Filter";
import TabCont from "./TabCont";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import useWindowDimensions from "../../hooks/useWindowDimension";
import _ from "lodash";
import { clearProductionData, clearRigsData, clearWellsData, handleSelectedRowId, handleTabIndex, handleUIDList, resetWellsAndRigsSliceToInitial } from "../../store/actions/wells-rigs-action";
import DeleteConfirmationModal from "../Modal/DeleteConfirmationModal";
import { compareTwoArray } from "../../../utils/helper";

function TabSection() {
    const {
        wellsAndRigs: {
            wellsData: { data: wellsList },
            rigsData: { data: rigsList },
            permitsData: { data: permitsList },
            productionData: { data: productionList },
            showTable,
            showTableLoader,
            fullScreen,
            downloadCol,
            openForeCast,
            uid,
            tabIndex,
            selectedRowId
        },
        esri: { featuresForStatistics },
        modal: { siteLoader }

    } = useAppSelector((state) => state);

    const dispatch = useAppDispatch();

    const { height } = useWindowDimensions()

    const [state, setState] = useState({
        noOfRows: 5,
        showWellsMaxLimitModal: false
    })
    const { noOfRows, showWellsMaxLimitModal } = state;
    const wellsMemo = useMemo(() => {
        return <TabCont />;
        // eslint-disable-next-line
    }, [wellsList]);
    const permitsMemo = useMemo(() => {
        return <TabCont />;
        // eslint-disable-next-line
    }, [permitsList]);
    const rigsMemo = useMemo(() => {
        return <TabCont />;
        // eslint-disable-next-line
    }, [rigsList]);
    const productionMemo = useMemo(() => {
        return <TabCont />;
        // eslint-disable-next-line
    }, [productionList]);

    useEffect(() => {
        if (fullScreen) { setState(prev => ({ ...prev, noOfRows: Math.trunc((height - 100) / 32) })) } else {
            setState(prev => ({ ...prev, noOfRows: 5 }))
        }
        // eslint-disable-next-line
    }, [
        // showTableLoader
        fullScreen
    ])
    let data =
        tabIndex === 0
            ? wellsList
            : tabIndex === 1
                ? rigsList
                : tabIndex === 2 ? permitsList : productionList;
    // this flag is used to unchecked the checkbox when data is selected from the existing table list
    const doNotRemoveRow = useRef(false);
    //this ref is used to store the uid already present in the table so it rows can get deleted when user click on new wells without closing the previous open pop-up.
    const alreadyPresentUID = useRef<string[]>([]);
    useEffect(() => {
        if (siteLoader) {
            return
        }

        if (!siteLoader && featuresForStatistics.length === 0) {
            let temp = doNotRemoveRow.current ? ([...(wellsList || [])]).map((item) => JSON.stringify(uid).includes(`${item.id}`) ? { ...item, checked: false } : item) : ([...(wellsList || [])]).map(_obj => ({
                ..._obj,
                // checked: false 
                ...(_obj.checked && alreadyPresentUID.current.join().includes(`${_obj.uid}`) && { checked: false })
            })).filter((item) => !JSON.stringify(uid).includes(`${item.id}`))
            uid.length && dispatch(handleUIDList({ uid: [], reset: true, data: temp, doNotRemoveRow: doNotRemoveRow.current }))
            doNotRemoveRow.current = false;
            alreadyPresentUID.current.length && (alreadyPresentUID.current = []);
            return
        }

        if (featuresForStatistics.length > 1000) {
            !showWellsMaxLimitModal && !sessionStorage.getItem("maxWellToast") && setState((prev) => ({ ...prev, showWellsMaxLimitModal: true }));
            sessionStorage.setItem("maxWellToast", "true")
            return
        }
        if (featuresForStatistics.length && featuresForStatistics.length <= 1000) {
            //The following check is implemented to reset the tab index to 0, ensuring that the "Wells" tab gets highlighted when wells are selected through the map. Currently, the "Rigs" table does not have a unique identifier (UID)

            if (tabIndex !== 0) {
                dispatch(handleTabIndex(0));
                dispatch(handleSelectedRowId(0))
                dispatch(
                    resetWellsAndRigsSliceToInitial(
                        true
                    )
                );
            }
            // let temp = featuresForStatistics.slice(0, 1000).filter((_id) => (!JSON.stringify(data).includes(_id) && `${_id}`))
            let temp = featuresForStatistics.filter((_id) => (!JSON.stringify(wellsList).includes(_id) && `${_id}`))

            if (compareTwoArray(uid, temp)) {
                return
            }

            // let temp_data = (data || []).map((_item) => JSON.stringify(featuresForStatistics).includes(`${_item.uid}`) ? { ..._item, checked: true } :_item );
            let temp_data = (wellsList || []).map((_item) => {
                if (JSON.stringify(featuresForStatistics).includes(`${_item.uid}`)) {
                    alreadyPresentUID.current = [...alreadyPresentUID.current, `${_item.uid}`]
                    return { ..._item, checked: true }
                } else {
                    return { ..._item, ...(uid.length && _item.checked && alreadyPresentUID.current.join().includes(`${_item.uid}`) && { checked: false }) }
                }
            });
            //the condition statement is added to remove the rows when user select new well without closing the pop-up of previous open pop-up
            // Jacob edited this conditional 7/24, to also make sure that the temp data does not filter out the selected
            // data.  What was happening was a user would click on a well on the map, that well data would get added to the wellsData
            // and it's API would become the selectedWellId, but then that well data was getting filtered out and the well card 
            // didn't have data to show.  I think this is because Jason removed the popover on the map.

            if (uid.length) {
                temp_data = temp_data.filter((item) => !JSON.stringify(uid).includes(`${item.uid}`) || item.id === selectedRowId);
                alreadyPresentUID.current.length && (alreadyPresentUID.current = []);
            }
            
            dispatch(handleUIDList({
                uid: temp.length ? temp : featuresForStatistics, reset: true, data: [...temp_data.filter(_item => _item.checked), ...temp_data.filter(_item => !_item.checked)]
            }
            ))

            if (temp.length) {
                dispatch(clearProductionData());
                dispatch(clearWellsData());
                dispatch(clearRigsData());
            } else {
                doNotRemoveRow.current = true;
            }
        }
        // eslint-disable-next-line
    }, [JSON.stringify(featuresForStatistics), siteLoader])
    return (
        <>
            <div className={(!downloadCol && showTableLoader) ? "search-con-loader-outlay" : 'd-none'}>
                {/* <div className="search-con-loader-outlay"> */}
                <div className="search-header">
                    <div className="searchTop">
                        <div className="searchByTab">
                            <ul className="nav">
                                <li className="nav-item">
                                    <button className="nav-link" type="button"> <span></span></button>
                                </li>
                                <li className="nav-item bt1"><button className="nav-link" type="button"><span></span></button>
                                </li>
                            </ul>
                        </div>
                        <div className="showhidebtn "></div>
                    </div>
                    <div className="searchbtm">
                        <div className="left">
                            <div className="searchInput"><span></span></div>
                            <div className="searchfilter"><span></span></div>
                        </div>
                        <div className="right">
                            <div className="greenbtn"></div>
                        </div>
                    </div>
                </div>
                <div className="tablelayout">

                    <table>
                        {
                            _.range(1, noOfRows).map((item, key) => {
                                return <tr key={key}>
                                    <th className="chk"><span></span></th>
                                    <th className="td"><span></span></th>
                                    <th className="td"><span></span></th>
                                    <th className="td"><span></span></th>
                                    <th className="td"><span></span></th>
                                    <th className="td"><span></span></th>
                                    <th className="td"><span></span></th>
                                </tr>
                            })
                        }

                        {/* <tr>
                            <td className="chk"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                        </tr> */}
                        {/* <tr>
                            <td className="chk"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                        </tr> */}
                        {/* <tr>
                            <td className="chk"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                        </tr> */}
                        {/* <tr>
                            <td className="chk"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                            <td className="td"><span></span></td>
                        </tr> */}

                    </table>
                </div>
            </div >
            <div className={(downloadCol || !showTableLoader) ? "search-container" : "d-none"}>
                <TabHeadingSec />
                {/* <div className={`tab-content searchCon ${fullScreen || openForeCast ? "isHide" : ""} ${showTable ? "" : "isHide"}`} id="myTabContent"> */}
                <div className={`tab-content searchCon ${openForeCast ? "isHide" : ""} ${showTable ? "" : "isHide"}`} id="myTabContent">
                    {/* <Filter hideTableFilters={true} hideAdvFilterBtn={false} /> */}
                    <div
                        className="tab-pane fade show active"
                        id="wells"
                        role="tabpanel"
                        aria-labelledby="wells-tab"
                    >
                        {wellsMemo}
                    </div>
                    <div
                        className="tab-pane fade"
                        id="rigs"
                        role="tabpanel"
                        aria-labelledby="rigs-tab"
                    >
                        {rigsMemo}
                    </div>
                    <div
                        className="tab-pane fade"
                        id="permits"
                        role="tabpanel"
                        aria-labelledby="permits-tab"
                    >
                        {permitsMemo}
                    </div>
                    <div
                        className="tab-pane fade"
                        id="production"
                        role="tabpanel"
                        aria-labelledby="production-tab"
                    >
                        {productionMemo}
                    </div>
                </div>
            </div >
            <DeleteConfirmationModal
                show={showWellsMaxLimitModal}
                handleClose={() => {
                    setState((prev) => ({ ...prev, showWellsMaxLimitModal: false }));
                    sessionStorage.removeItem("sessionStorage")
                }}
                confirmBtnClick={() => {
                    setState((prev) => ({ ...prev, showWellsMaxLimitModal: false }))
                    sessionStorage.removeItem("sessionStorage")

                }}
                closeBtn={true}
                content={<p>Please limit your search to 1,000 wells or less.</p>}
            />
        </>
    );
}

export default TabSection;
