import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import {
    getAnalyticsData,
    handleAnalyticsProdFilters,
    handleNormalize,
    handleResizableWidth,
    toggleViewAnalytics,
    handleSelectedRowId,
    getProductionDataByLease,
    clearSelectedWellsRigs,
    handleCheckedItemList,
    setMonthlyDataLoading,
    setCumDataLoading
} from "../store/actions/wells-rigs-action";
import { ProductionDataObj, RigsDataObj, WellsAndPermitsObject } from "../models/redux-models";
import {
    ANALYTICS_CUM_TAB, LINE_CHART_MAX_ITEM_ALLOWED, ANALYTICS_MONTHLY_TAB,
    VERTICAL, ANALYTICS_DEFAULT_WIDTH,
} from "../../utils/helper";
import { toast } from "react-toastify";
import AnalyticsRightSidebarProductionTab from "./AnalyticsRightSidebarProductionTab"
import AnalyticsRightSidebarWellAttributesTab from "./AnalyticsRightSidebarWellAttributesTab"
import { getSelectedWell } from "./AoiUtils"


const AnalyticsRightSidebar = ({ resizableWidth, draggableRef, startDrag }: { resizableWidth: number, draggableRef: React.RefObject<HTMLDivElement>, startDrag: (event: React.MouseEvent<HTMLDivElement>) => void }) => {
    const {
        wellsAndRigs: {
            viewAnalytics,
            tabIndex,
            selectedRowId,
            analyticsData: { monthlyDataLoading, cumDataLoading, type, xAxisFilter, xAxisFilterCum, normalized, action, action_cum, apiList, apiListObj, },
            checkedItemList,
            analyticsTabIndex,
            wellsData: { data: wellsDataList },
            rigsData: { data: rigsDataList },
            permitsData: { data: permitsDataList }
        },
        auth: { user: { access_token } },
        esri: { featuresForStatistics },
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();


    {/* The goal here is have the sidebar open with well information when:
        * the well is clicked in the table 
        * the well is clicked on the map
        
        The first condition is handled in the components/common/WellsAndRigs/Filter
        whereas, the second condition is handled when featuresForStatistics is updated via a map click. 
        We hook into this state change here
         */}
    const [oldSelectedWellApi, setOldSelectedWellApi] = useState<string | number>(0);
    const [oldSelectedWellApiLease, setOldSelectedWellApiLease] = useState<string | number>(0);
    useEffect(() => {

        // Case 1: The user selected multiple well on an AOI. Then one well for the well card should not be selected (or collapse if open and unselect a selected well on the table)
        // Case 2: The user selects just 1 well on the map, then well card should pop out and the well in the table should be selected
        // Case 3: The user unselects a well on the map, and the well card should collapse and the well should get unselected in the table
        const withoutDupsIDs = [...new Set(featuresForStatistics)]
        // convert UID 12345-1234 to API 12345
        // Sometimes the feature for statistics was returning 2 uids but the same API
        // as in 12345-0 and 12345-1.  But since they are the same API, it should count as 
        // 1 well being selected and open the well card.  This filter checks if it's just 1 well
        const withoutDupsAPIs: string[] = []
        withoutDupsIDs.forEach((uid: string) => {
            const splitArray = uid.split("-");
            if (splitArray && splitArray[0] && !withoutDupsAPIs.includes(splitArray[0])) {
                withoutDupsAPIs.push(splitArray[0])
            }
        })
        if (withoutDupsAPIs && withoutDupsAPIs.length === 1 && withoutDupsIDs[0] !== oldSelectedWellApi) {
            setOldSelectedWellApi(withoutDupsIDs[0])
            dispatch(handleSelectedRowId(withoutDupsIDs[0] ?? ''))
            let wellOrRigsData: (WellsAndPermitsObject | RigsDataObj | ProductionDataObj)[] | null | undefined = []
            if (tabIndex === 0) {
                wellOrRigsData = wellsDataList?.filter((well: WellsAndPermitsObject) => well.id === withoutDupsIDs[0])
            } else if (tabIndex === 1) {
                wellOrRigsData = rigsDataList?.filter((rig: RigsDataObj) => rig.id === withoutDupsIDs[0])
            } else if (tabIndex === 2) {
                wellOrRigsData = permitsDataList?.filter((permit: WellsAndPermitsObject) => permit.id === withoutDupsIDs[0])
            }
            if (wellOrRigsData && wellOrRigsData.length === 1) {

                dispatch(handleCheckedItemList(wellOrRigsData))
            }


        } else if (oldSelectedWellApi && (!withoutDupsAPIs || withoutDupsAPIs.length > 1 || withoutDupsAPIs.length === 0)) {
            setOldSelectedWellApi(0)
            setOldSelectedWellApiLease(0)
            dispatch(handleSelectedRowId(0))
        }
    }, [featuresForStatistics])

    useEffect(() => {
        const selectedWell = getSelectedWell(wellsDataList, selectedRowId)
        if (selectedWell?.well_api && selectedWell?.well_api !== oldSelectedWellApiLease) {
            setOldSelectedWellApiLease(selectedWell?.well_api)
            dispatch(getProductionDataByLease(selectedWell?.well_api))
            if (analyticsTabIndex === 1) {
                dispatch(getAnalyticsData(access_token, { api_id: [getSelectedWell(wellsDataList, selectedRowId)?.well_api ?? ''], type: "monthlyTab", action, XAxis: xAxisFilter }, false));
            }
        }
    }, [wellsDataList, selectedRowId])

    useEffect(() => {
        // We should not show the Analytics sidebar if there are no wells in the list.  However,
        // we should show the sidebar if the selected tab is not 0 to show well attributes
        if (checkedItemList.length === 0 && viewAnalytics && analyticsTabIndex === 0) {
            dispatch(toggleViewAnalytics());
            dispatch(handleResizableWidth(ANALYTICS_DEFAULT_WIDTH));
            return
        }

        if (checkedItemList.length > 0 && viewAnalytics) {
            // let tempList = (checkedItemList.length > LINE_CHART_MAX_ITEM_ALLOWED ? [...checkedItemList].slice(0, LINE_CHART_MAX_ITEM_ALLOWED) : checkedItemList)
            let tempList = checkedItemList

            if (JSON.stringify(tempList) === JSON.stringify(apiListObj)) {
                return
            }

            let tempApiListObjLength = tempList.filter((_item) => {
                return tabIndex === 1 ? (_item as RigsDataObj)['profile']?.toLocaleLowerCase() === VERTICAL : (_item as WellsAndPermitsObject)["drill_type"]?.toLocaleLowerCase() === VERTICAL
            }).length
            // we need to set the loading to true to get the updated production from the getAnalyticsData call
            // in the useEffect below that triggers after an update to the apiList
            dispatch(setMonthlyDataLoading(true))
            dispatch(setCumDataLoading(true))
            dispatch(handleAnalyticsProdFilters({
                apiList: tempList?.map((item) => (tabIndex === 0 || tabIndex === 2) ? (item as WellsAndPermitsObject).well_api : tabIndex === 3 ? (item as ProductionDataObj).well_api : (item as RigsDataObj).api),
                apiListObj: tempList,
                apiListObjLength: tempApiListObjLength
            }))

            if (checkedItemList.length > LINE_CHART_MAX_ITEM_ALLOWED) {
                setState((prev) => ({
                    ...prev,
                    // showDropDownOption: true,
                    closeOilMaxMsg: false, closeGasMaxMsg: false
                }))
            }
            dispatch(handleAnalyticsProdFilters({ action: checkedItemList.length > LINE_CHART_MAX_ITEM_ALLOWED ? 'average' : 'none', type }));
            ((tempApiListObjLength && normalized) || tempApiListObjLength) && toast.info("Your selection includes vertical wells.  These are not included in the normalization calculation.")
            tempList.length === tempApiListObjLength && dispatch(handleNormalize(false))

        }
        // eslint-disable-next-line
    }, [checkedItemList, viewAnalytics])

    const [state, setState] = useState<{
        //  dowData: DowData[], 
        showDropDownOption: boolean, closeOilMaxMsg: boolean, closeGasMaxMsg: boolean
    }>({
        //  dowData: [],
        showDropDownOption: false, closeOilMaxMsg: false, closeGasMaxMsg: false
    })

    const {
        // dowData,
        showDropDownOption, closeOilMaxMsg, closeGasMaxMsg } = state;

    useEffect(() => {
        if (apiList.length) {
            // if (apiList.length > LINE_CHART_MAX_ITEM_ALLOWED && (action === "none" && action_cum === "none")) {
            //     return
            // }
            if (analyticsTabIndex === 0) {
                //only selecting number (LINE_CHART_MAX_ITEM_ALLOWED) max allowed items api to send to backend
                // monthlyDataLoading && type === ANALYTICS_MONTHLY_TAB && dispatch(getAnalyticsData(access_token, { api_id: apiList, type: ANALYTICS_MONTHLY_TAB, action }, checkedItemList.length > LINE_CHART_MAX_ITEM_ALLOWED ? true : false));

                // cumDataLoading && type === ANALYTICS_CUM_TAB && dispatch(getAnalyticsData(access_token, { api_id: apiList, type: ANALYTICS_CUM_TAB, action: action_cum }, checkedItemList.length > LINE_CHART_MAX_ITEM_ALLOWED ? true : false))

                monthlyDataLoading && type === ANALYTICS_MONTHLY_TAB && dispatch(getAnalyticsData(access_token, { api_id: apiList, type: ANALYTICS_MONTHLY_TAB, action, XAxis: xAxisFilter }, false));

                cumDataLoading && type === ANALYTICS_CUM_TAB && dispatch(getAnalyticsData(access_token, { api_id: apiList, type: ANALYTICS_CUM_TAB, action: action_cum, XAxis: xAxisFilterCum }, false))


                // donutChartDataLoading && dispatch(getAnalyticsDonutData(access_token, { api_id: apiList }))

                return
            }


        }

        // eslint-disable-next-line    
    }, [wellsDataList, selectedRowId, JSON.stringify(apiList), analyticsTabIndex, type, action, action_cum, xAxisFilter, xAxisFilterCum])


    return (
        <>
            <div className={`${viewAnalytics ? "analyticsCon open" : "d-none"}`}
                onClick={() => {
                    showDropDownOption && setState((prev) => ({ ...prev, showDropDownOption: false }))
                }}
                id="Resizable2"
                style={{
                    ...({ width: `${100 - (resizableWidth)}%` }),
                }}
            >
                <div className="column-full-height">
                    <div
                        ref={draggableRef}
                        onMouseDown={startDrag}
                        className="draggableEle"
                    ></div>
                    <div className="analytics-header">{analyticsTabIndex === 0 ? (<span><img src="images/analytics.svg" alt="" /> Analytics</span>) : (<span>
                        <img src="images/pumpjack.svg" alt="Pumpjack" className="margin-bottom-point-3" /> Well Attributes
                    </span>)}

                        <span className="close-btn"
                            onClick={(e) => {
                                dispatch(clearSelectedWellsRigs())
                                dispatch(handleResizableWidth(ANALYTICS_DEFAULT_WIDTH));

                            }}
                        >
                            <i className="fa-solid fa-xmark" />
                        </span>
                    </div>
                    {analyticsTabIndex === 0 ? <AnalyticsRightSidebarProductionTab /> :
                        analyticsTabIndex === 1 ? <AnalyticsRightSidebarWellAttributesTab /> : <></>}</div>
            </div>

        </>
    );
};

export default AnalyticsRightSidebar;
