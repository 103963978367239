import { Navigate, Outlet, useLocation, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../components/hooks/redux-hooks";
import { useLayoutEffect, useState } from "react";
import Spinner2 from "../components/common/Spinner2";
import { cartSelectBasin, searchPathname } from "../utils/helper";

const AuthRoute = () => {
    const { auth, subscriptionSettings: { subscriptionData, upgrade_subscription } } = useAppSelector((state) => state);
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const [state, setState] = useState<{
        isAuthenticated: boolean | null;
    }>({
        isAuthenticated: null,
    });

    const { isAuthenticated } = state;

    const location = useLocation()
    const go_national_plan = subscriptionData && (subscriptionData['details'] || []).find((item) => item.line_item_type === 3) ? true : false

    useLayoutEffect(() => {
        setState((prev) => ({
            ...prev,
            isAuthenticated: auth.isAuthenticated,
        }));

        //for search page to store highlight id when user is not login. we doing this so we can navigate to same route
        if (id && location.pathname === searchPathname && !auth.isAuthenticated) {
            sessionStorage.setItem("highlightWellId", id);
        }
        // eslint-disable-next-line
    }, [auth]);

    if (isAuthenticated === null) {
        return <Spinner2 />;
    }

    return isAuthenticated !== null && isAuthenticated !== false ? (
        <>
            {auth.user.company_configs.free_trial_period_enabled && auth.user.company_configs.trial_expired && location.pathname !== cartSelectBasin ? <Navigate to={cartSelectBasin} /> :
                auth.user.company_configs.free_trial_period_enabled && !auth.user.company_configs.trial_expired && location.pathname === cartSelectBasin && subscriptionData !== null && !upgrade_subscription ?
                    < Navigate to={"/search"} /> :
                    go_national_plan && location.pathname === cartSelectBasin ? <Navigate to={"/search"} /> : <Outlet />}
        </>
    ) : (
        <Navigate to="/" />
    );
};

export default AuthRoute;
