import React, { useState } from 'react'
import Scrollbars from 'react-custom-scrollbars';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { handleAdvancedFilter } from '../store/actions/cart-basin-to-county-actions';

function AdvancedFilterCom() {
    const {
        cartBasinToCounty: { showAdvancedFilter, leftTab },
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();
    const [state, setState] = useState({
        firstNestedMenu: false,
        menuIndex: 0,
    });
    const menuList = [
        {
            label: "Well",
            img: "images/well-icon.svg",
            onClick: () => {
                setState((prev) => ({ ...prev, firstNestedMenu: true, menuIndex: 0 }))
            },
            menuIndex: 0
        },
        {
            label: "Rigs",
            img: "images/rigs-icon.svg",
            onClick: () => {
                setState((prev) => ({ ...prev, firstNestedMenu: true, menuIndex: 1 }))
            },
            menuIndex: 1
        }
    ]
    return (
        <div className={`advancedFilterCon ${showAdvancedFilter ? "" : "d-none"} ${!leftTab ? "d-none" : ""}`
        }>
            <div className="filter-header">
                <button className="btn-filter" onClick={() => dispatch(handleAdvancedFilter(true))}><img src="images/advance-filter.svg" alt="Filter" /> Advanced</button>
                <button className="btclose" onClick={() => {
                    let myElement = document.getElementById("filterToggleMove");
                    if (myElement) myElement.classList.remove("advanceFiltermap")
                    dispatch(handleAdvancedFilter(false))
                }} ><i className="fa-solid fa-close"></i></button>
            </div>
            <div className="filter-form">
                <form>
                    <Scrollbars
                        className="filtersidebar-scroll"
                        autoHeightMin={0}
                        renderThumbVertical={(props) => (
                            <div {...props} className="thumb-vertical" />
                        )}
                        renderTrackVertical={(props) => (
                            <div {...props} className="track-vertical" />
                        )}
                    >
                        <div className="filtersidebar">
                            <div className="selectSavedFiltes">
                                <a href="void:(0)" className="btn">
                                    <img src="images/s-filter.svg" alt="" />
                                    Select saved filters
                                    <span className="down-arrow">&nbsp;</span>
                                </a>
                            </div>
                            <div className="segment">
                                <label className="label">Segment wells where:</label>
                                <div className="segment-row">
                                    <div className="inputs">
                                        <div className="selectInput icon">
                                            <select name="" className="form-control">
                                                <option value="">Operator</option>
                                            </select>
                                        </div>
                                        <div className="selectInput">
                                            <select name="" className="form-control">
                                                <option value="">is equal to</option>
                                            </select>
                                        </div>
                                        <div className="textInput">
                                            <input type="text" className="form-control cursor" placeholder="Enter value..." />
                                        </div>
                                    </div>
                                    <div className="action dropdown">
                                        <a href="javascript:void(0)" className='dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-ellipsis"></i></a>
                                        <div className='dropdown-menu'>
                                            <div className="action-block">
                                                <button type="button" className="btn copy"><i className="fa-regular fa-copy"></i></button>
                                                <button type="button" className="btn del"><i className="fa-solid fa-trash-can"></i></button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="and">
                                    <select name="" className="form-control">
                                        <option value="">AND</option>
                                    </select>
                                </div>
                                <div className="group-row">
                                    <div className="groupBlock">
                                        <div className="dragbtn"><img src="images/drag.svg" alt="" /></div>
                                        <div className="segment-row">
                                            <div className="inputs">
                                                <div className="selectInput icon">
                                                    <select name="" className="form-control">
                                                        <option value="">Operator</option>
                                                    </select>
                                                </div>
                                                <div className="selectInput">
                                                    <select name="" className="form-control">
                                                        <option value="">is equal to</option>
                                                    </select>
                                                </div>
                                                <div className="textInput">
                                                    <input type="text" className="form-control cursor" placeholder="Enter value..." />
                                                </div>
                                            </div>
                                            <div className="action dropdown">
                                                <a href="javascript:void(0)" className='dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-ellipsis"></i></a>
                                                <div className='dropdown-menu'>
                                                    <div className="action-block">
                                                        <button type="button" className="btn copy"><i className="fa-regular fa-copy"></i></button>
                                                        <button type="button" className="btn del"><i className="fa-solid fa-trash-can"></i></button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="and">
                                        <select name="" className="form-control">
                                            <option value="">AND</option>
                                        </select>
                                    </div>
                                    <div className="groupBlock">
                                        <div className="dragbtn"><img src="images/drag.svg" alt="" /></div>
                                        <div className="segment-row">
                                            <div className="inputs">
                                                <div className="selectInput icon">
                                                    <select name="" className="form-control">
                                                        <option value="">Operator</option>
                                                    </select>
                                                </div>
                                                <div className="selectInput">
                                                    <select name="" className="form-control">
                                                        <option value="">is equal to</option>
                                                    </select>
                                                </div>
                                                <div className="textInput">
                                                    <input type="text" className="form-control cursor" placeholder="Enter value..." />
                                                </div>
                                            </div>
                                            <div className="action dropdown">
                                                <a href="javascript:void(0)" className='dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-ellipsis"></i></a>
                                                <div className='dropdown-menu'>
                                                    <div className="action-block">
                                                        <button type="button" className="btn copy"><i className="fa-regular fa-copy"></i></button>
                                                        <button type="button" className="btn del"><i className="fa-solid fa-trash-can"></i></button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="addFilter dropdown">
                                    <button className="btn btn-primary " type="button">
                                        + Add filter or group
                                    </button>
                                    <ul className="dropdown-menu">
                                        {
                                            menuList.map((item, index) => {
                                                return <li key={index} onClick={item.onClick}><a className="dropdown-item" href="#"><img src={item.img} alt="" /> <span>{item.label}</span></a></li>
                                            })
                                        }
                                        <li className="add-group"><a className="dropdown-item" href="#"><img src="images/add-group-icon.svg" alt="" /> <span>Add a group</span></a></li>
                                    </ul>
                                    <div className={state["firstNestedMenu"] ? "subdropDownmenu" : "d-none"}>
                                        <div className="header" onClick={() => {
                                            setState((prev) => ({ ...prev, menuIndex: 0, firstNestedMenu: false }));
                                        }}><img src="images/left-arrow.svg" alt="" /> {state["menuIndex"] === 0 ? "Well" : "Rigs"}</div>
                                        <h3>Well Information</h3>
                                        <ul>
                                            <li><a href="">Operator</a></li>
                                            <li><a href="">Well Name</a></li>
                                            <li><a href="">API</a></li>
                                            <li><a href="">Well Type</a></li>
                                            <li><a href="">Depth</a></li>
                                            <li><a href="">Well Orientation</a></li>
                                        </ul>
                                        <h3>Well Information</h3>
                                        <ul>
                                            <li><a href="">Country</a></li>
                                            <li><a href="">State</a></li>
                                            <li><a href="">Basin</a></li>
                                            <li><a href="">Section</a></li>
                                            <li><a href="">Township</a></li>
                                            <li><a href="">Range</a></li>
                                        </ul>
                                        <h3>Activity</h3>
                                        <ul>
                                            <li><a href="">Spud Date</a></li>
                                            <li><a href="">Completion Date</a></li>
                                            <li><a href="">Production Date</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                    <div className="button-action">
                        <button
                            type="button"
                            className="btn btn-outline">
                            Clear
                        </button>
                        <button
                            type="submit"
                            className="btn btn-outline-white"
                        >
                            <img src="images/save.svg" alt="" /> Save
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary"
                        >
                            Apply Filters
                        </button>
                    </div>
                </form>
            </div>

        </div >
    )
}

export default AdvancedFilterCom